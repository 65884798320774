<template>
  <div
    v-if="feature_type"
    id="feature-type-detail"
  >
    <div class="ui stackable grid">
      <div class="five wide column">
        <div
          id="feature-type-title"
          class="ui attached secondary segment"
        >
          <h1 class="ui center aligned header ellipsis">
            <img
              v-if="feature_type.geom_type === 'point'"
              class="ui medium image"
              alt="Géométrie point"
              src="@/assets/img/marker.png"
            >
            <img
              v-if="feature_type.geom_type === 'linestring'"
              class="ui medium image"
              alt="Géométrie ligne"
              src="@/assets/img/line.png"
            >
            <img
              v-if="feature_type.geom_type === 'polygon'"
              class="ui medium image"
              alt="Géométrie polygone"
              src="@/assets/img/polygon.png"
            >
            <img
              v-if="feature_type.geom_type === 'multipoint'"
              class="ui medium image"
              alt="Géométrie point"
              src="@/assets/img/multimarker.png"
            >
            <img
              v-if="feature_type.geom_type === 'multilinestring'"
              class="ui medium image"
              alt="Géométrie ligne"
              src="@/assets/img/multiline.png"
            >
            <img
              v-if="feature_type.geom_type === 'multipolygon'"
              class="ui medium image"
              alt="Géométrie polygone"
              src="@/assets/img/multipolygon.png"
            >
            <span
              v-if="feature_type.geom_type === 'none'"
              class="ui medium image"
              title="Aucune géométrie"
            >
              <i class="ui icon big outline file" />
            </span>
            {{ feature_type.title }}
          </h1>
        </div>
        <div class="ui attached segment">
          <div class="ui basic segment">
            <div class="ui horizontal tiny statistic">
              <div
                :class="{ active: featuresLoading }"
                class="ui inverted dimmer"
              >
                <div class="ui text loader">
                  Récupération des signalements en cours...
                </div>
              </div>
              <div class="value">
                {{ isOnline ? featuresCount : '?' }}
              </div>
              <div
                class="label"
              >
                Signalement{{ featuresCount > 1 || !isOnline ? "s" : "" }}
              </div>
            </div>

            <h3 class="ui header">
              Champs
            </h3>
            <div class="ui divided list">
              <div
                v-for="(field, index) in feature_type.customfield_set"
                :key="field.name + index"
                class="item"
              >
                <div class="right floated content custom-field">
                  <div class="description">
                    {{ field.field_type }}
                  </div>
                </div>
                <div class="content">
                  {{ field.label }} ({{ field.name }})
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="ui bottom attached secondary segment">
          <div
            v-if="user && permissions.can_create_feature"
            class="ui styled accordion"
            data-test="features-import"
          >
            <div
              id="toggle-show-import"
              :class="['title', { active: showImport && isOnline, nohover: !isOnline }]"
              @click="toggleShowImport"
            >
              <i
                class="dropdown icon"
                aria-hidden="true"
              />
              Importer des signalements
            </div>
            <div :class="['content', { active: showImport && isOnline }]">
              <div class="field">
                <label
                  class="ui icon button ellipsis"
                  for="json_file"
                >
                  <i
                    class="file icon"
                    aria-hidden="true"
                  />
                  <span class="label">{{ geojsonFileToImport.name }}</span>
                </label>
                <input
                  id="json_file"
                  type="file"
                  accept="application/json, .json, .geojson"
                  style="display: none"
                  name="json_file"
                  @change="onGeojsonFileChange"
                >
              </div>

              <div
                v-if="feature_type.geom_type === 'point' || feature_type.geom_type === 'none'"
                class="field"
              >
                <label
                  class="ui icon button ellipsis"
                  for="csv_file"
                >
                  <i
                    class="file icon"
                    aria-hidden="true"
                  />
                  <span class="label">{{ csvFileToImport.name }}</span>
                </label>
                <input
                  id="csv_file"
                  type="file"
                  accept="application/csv, .csv"
                  style="display: none"
                  name="csv_file"
                  @change="onCsvFileChange"
                >
              </div>
              <router-link
                v-if="
                  IDGO &&
                    permissions &&
                    permissions.can_create_feature
                "
                :to="{
                  name: 'catalog-import',
                  params: {
                    slug,
                    feature_type_slug: $route.params.feature_type_slug
                  },
                }"
                class="ui icon button import-catalog"
              >
                Importer les signalements à partir de {{ CATALOG_NAME|| 'IDGO' }}
              </router-link>

              <ul
                v-if="importError"
                class="errorlist"
              >
                <li>
                  {{ importError }}
                </li>
              </ul>

              <button
                id="start-import"
                :disabled="
                  (geojsonFileToImport.size === 0 && !$route.params.geojson) &&
                    (csvFileToImport.size === 0 && !$route.params.csv)
                "
                class="ui fluid teal icon button"
                @click="geojsonFileToImport.size !== 0 ? importGeoJson() : importCSV()"
              >
                <i
                  class="upload icon"
                  aria-hidden="true"
                />
                Lancer l'import
              </button>
              <ImportTask
                v-if="importsForFeatureType.length > 0"
                ref="importTask"
                :imports="importsForFeatureType"
                @reloadFeatureType="reloadFeatureType"
              />
            </div>
          </div>
          <div
            class="ui styled accordion"
            data-test="features-export"
          >
            <div
              :class="['title', { active: !showImport && isOnline, nohover: !isOnline }]"
              @click="toggleShowImport"
            >
              <i
                class="dropdown icon"
                aria-hidden="true"
              />
              Exporter les signalements
            </div>
            <div :class="['content', { active: !showImport && isOnline }]">
              <p>
                Vous pouvez télécharger tous les signalements qui vous sont
                accessibles.
              </p>
              <select
                v-model="exportFormat"
                class="ui fluid dropdown"
                style="margin-bottom: 1em;"
              >
                <option value="GeoJSON">
                  {{ feature_type.geom_type === 'none' ? 'JSON' : 'GeoJSON' }}
                </option>
                <option
                  v-if="feature_type.geom_type === 'point' || feature_type.geom_type === 'none'"
                  value="CSV"
                >
                  CSV
                </option>
              </select>
              <button
                :class="{ loading: exportLoading }"
                type="button"
                class="ui fluid teal icon button"
                @click="exportFeatures"
              >
                <i
                  class="download icon"
                  aria-hidden="true"
                />
                Exporter
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="isOnline"
        class="nine wide column"
      >
        <h3 class="ui header">
          Derniers signalements
        </h3>
        <div
          :class="{ active: featuresLoading }"
          class="ui inverted dimmer"
        >
          <div class="ui text loader">
            Récupération des signalements en cours...
          </div>
        </div>
        <div
          v-if="
            importsForFeatureType &&
              importsForFeatureType.length &&
              importsForFeatureType.some((el) => el.status === 'pending')
          "
          class="ui message info"
          data-test="wait-import-message"
        >
          <p>
            Des signalements sont en cours d'import. Pour suivre le statut de
            l'import, cliquez sur "Importer des Signalements".
          </p>
        </div>
        <div
          v-else-if="waitMessage"
          class="ui message info"
        >
          <p>
            L'import des signalements a été lancé.
            Vous pourrez suivre le statut de l'import dans quelques instants...
          </p>
        </div>
        <div
          v-for="(feature, index) in lastFeatures"
          :key="feature.feature_id + index"
          class="ui small header"
          data-test="last-features"
        >
          <span
            v-if="feature.status === 'archived'"
            data-tooltip="Archivé"
          >
            <i
              class="grey archive icon"
              aria-hidden="true"
            />
          </span>
          <span
            v-else-if="feature.status === 'pending'"
            data-tooltip="En attente de publication"
          >
            <i
              class="teal hourglass outline icon"
              aria-hidden="true"
            />
          </span>
          <span
            v-else-if="feature.status === 'published'"
            data-tooltip="Publié"
          >
            <i
              class="olive check icon"
              aria-hidden="true"
            />
          </span>
          <span
            v-else-if="feature.status === 'draft'"
            data-tooltip="Brouillon"
          >
            <i
              class="orange pencil alternate icon"
              aria-hidden="true"
            />
          </span>
          <FeatureFetchOffsetRoute
            :feature-id="feature.feature_id"
            :properties="feature"
          />
          <div class="sub header">
            <div>
              {{
                feature.description
                  ? feature.description.substring(0, 200)
                  : "Pas de description disponible"
              }}
            </div>
            <div>
              [ Créé le {{ feature.created_on | formatDate }}
              <span v-if="user">
                par {{ feature.display_creator }}</span>
              ]
            </div>
          </div>
        </div>
        <router-link
          v-if="project"
          :to="{ name: 'liste-signalements', params: { slug } }"
          class="ui right labeled icon button margin-25"
        >
          <i
            class="right arrow icon"
            aria-hidden="true"
          />
          Voir tous les signalements
        </router-link>
        <router-link
          v-if="permissions.can_create_feature && feature_type.geom_type && !feature_type.geom_type.includes('multi')"
          :to="{
            name: 'ajouter-signalement',
            params: { slug_type_signal: feature_type.slug },
          }"
          class="ui icon button button-hover-green margin-25"
        >
          Ajouter un signalement
        </router-link>
        <br>
      </div>
      <div
        v-else
        class="nine wide column"
      >
        <h3 class="ui header">
          Derniers signalements
        </h3>
        <div class="ui message info">
          <p>
            Information non disponible en mode déconnecté.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { csv } from 'csvtojson';

import { mapActions, mapMutations, mapGetters, mapState } from 'vuex';
import { formatStringDate, transformProperties } from '@/utils';

import FeatureFetchOffsetRoute from '@/components/Feature/FeatureFetchOffsetRoute';
import ImportTask from '@/components/ImportTask';
import featureAPI from '@/services/feature-api';
import { fileConvertSizeToMo, determineDelimiter, parseCSV, checkLonLatValues } from '@/assets/js/utils';

const geojsonFileToImport = {
  name: 'Sélectionner un fichier GeoJSON ...',
  size: 0,
};
const csvFileToImport = {
  name: 'Sélectionner un fichier CSV ...',
  size: 0,
};

export default {
  name: 'FeatureTypeDetail',
  
  components: {
    FeatureFetchOffsetRoute,
    ImportTask,
  },

  filters: {
    formatDate(value) {
      return formatStringDate(value);
    },
  },

  props: {
    geojson: {
      type: Object,
      default: null,
    },
    csv: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      importError: '',
      geojsonFileToImport,
      csvFileToImport,
      showImport: false,
      slug: this.$route.params.slug,
      featureTypeSlug: this.$route.params.feature_type_slug,
      featuresLoading: true,
      loadingImportFile: false,
      waitMessage: false,
      exportFormat: 'GeoJSON',
      exportLoading: false,
      lastFeatures: [],
      featuresCount: 0,
    };
  },

  computed: {
    ...mapGetters([
      'permissions',
    ]),
    ...mapGetters('projects', [
      'project'
    ]),
    ...mapGetters('feature-type', [
      'feature_type'
    ]),
    ...mapState([
      'reloadIntervalId',
      'configuration',
      'isOnline',
      'user',
    ]),
    ...mapState('projects', [
      'project'
    ]),
    ...mapState('feature-type', [
      'feature_types',
      'importFeatureTypeData',
      'selectedPrerecordedListValues'
    ]),
    importsForFeatureType() { // filter import task datas only for this feature type
      if (this.importFeatureTypeData) {
        return this.importFeatureTypeData.filter((el) => el.feature_type_title === this.featureTypeSlug);
      }
      return [];
    },
    CATALOG_NAME() {
      return this.configuration.VUE_APP_CATALOG_NAME;
    },
    IDGO() {
      return this.$store.state.configuration.VUE_APP_IDGO;
    },
  },

  watch: {
    feature_type(newValue) {
      this.toggleJsonUploadOption(newValue);
    }
  },

  created() {
    if (!this.project) {
      this.$store.dispatch('projects/GET_PROJECT', this.slug);
      this.$store.dispatch('projects/GET_PROJECT_INFO', this.slug);
    }
    this.SET_CURRENT_FEATURE_TYPE_SLUG(
      this.featureTypeSlug
    );
    this.$store.dispatch('feature-type/GET_IMPORTS', {
      project_slug:  this.$route.params.slug,
      feature_type: this.featureTypeSlug
    });
    this.getLastFeatures();
    if (this.$route.params.type === 'external-geojson') {
      this.showImport = true;
    }
    // empty prerecorded lists in case the list has been previously loaded with a limit in other component like ExtraForm 
    this.SET_PRERECORDED_LISTS([]);
    // This function is also called by watcher at this stage, but to be safe in edge case
    this.toggleJsonUploadOption(this.feature_type);
  },


  methods: {
    ...mapMutations([
      'DISPLAY_MESSAGE',
    ]),
    ...mapMutations('feature-type', [
      'SET_CURRENT_FEATURE_TYPE_SLUG',
      'SET_FILE_TO_IMPORT',
      'SET_PRERECORDED_LISTS'
    ]),
    ...mapActions('feature-type', [
      'GET_PROJECT_FEATURE_TYPES',
      'GET_SELECTED_PRERECORDED_LIST_VALUES',
      'SEND_FEATURES_FROM_CSV',
    ]),
    ...mapActions('feature', [
      'GET_PROJECT_FEATURES'
    ]),

    getLastFeatures() {
      let url = `${this.$store.state.configuration.VUE_APP_DJANGO_API_BASE}projects/${this.slug}/feature-paginated/?feature_type_slug=${this.featureTypeSlug}&ordering=-created_on&limit=5&offset=0`;
      featureAPI.getPaginatedFeatures(url)
        .then((data) => {
          if (data) {
            this.lastFeatures = data.results;
            this.featuresCount = data.count;
          }
          this.featuresLoading = false;
        });
    },

    reloadFeatureType() {
      this.GET_PROJECT_FEATURE_TYPES(this.slug);
      this.getLastFeatures();
    },
    
    toggleShowImport() {
      this.showImport = !this.showImport;
    },

    /**
     * In the case of a non geographical feature type, replace geoJSON by JSON in file to upload options
     *
     * @param {Object} featureType - The current featureType.
     */
    toggleJsonUploadOption(featureType) {
      if (featureType && featureType.geom_type === 'none') {
        this.geojsonFileToImport = {
          name: 'Sélectionner un fichier JSON ...',
          size: 0,
        };
      }
    },

    async checkPreRecordedValue(fieldValue, listName) {
      const fieldLabel = fieldValue.label || fieldValue;
      // encode special characters like apostrophe or white space
      const encodedPattern = encodeURIComponent(fieldLabel);
      // query existing prerecorded list values (with label to limit results in response, there could be many) and escape special characters, since single quote causes error in backend
      await this.GET_SELECTED_PRERECORDED_LIST_VALUES({ name: listName, pattern: encodedPattern });
      // check if the value exist in available prerecorded list values
      return this.selectedPrerecordedListValues[listName].some((el) => el.label === fieldLabel);        
    },

    /**
     * Validates the imported data against the pre-determined field types.
     *
     * This function iterates over all imported features and checks if each property's value matches
     * the expected type specified in the feature type schema. It accommodates specific type conversions,
     * such as allowing numerical strings for 'char' or 'text' fields and converting string representations
     * of booleans and lists as necessary.
     *
     * @param {Array} features - The array of imported features to validate.
     * @returns {boolean} Returns true if all features pass the validation; otherwise, false with an error message.
     */
    async isValidTypes(features) {
      this.importError = '';
      
      // Extract relevant field type information from the feature type schema
      const fields = this.feature_type.customfield_set.map((el) => {
        return {
          name: el.name,
          field_type: el.field_type,
          options: el.options,
        };
      });
      let count = 1;
      for (const feature of features) {
        this.$store.commit('DISPLAY_LOADER', `Vérification du signalement ${count} sur ${features.length}`);
        for (const { name, field_type, options } of fields) {
          const properties = feature.properties || feature;
          
          if (name in properties) {
            let fieldInFeature = properties[name];

            // Convert boolean strings from CSV to actual booleans
            if (field_type === 'boolean') {
              fieldInFeature = fieldInFeature === 'True' ? true : (fieldInFeature === 'False' ? false : fieldInFeature);
            }

            const customType = transformProperties(fieldInFeature);

            // Validate field only if it has a non-null, non-empty, defined value
            if (fieldInFeature !== null && fieldInFeature !== '' && fieldInFeature !== undefined) {
              
              // Handle 'list' type by checking if value is among the defined options
              if (field_type === 'list') {
                if (!options.includes(fieldInFeature)) {
                  this.importError = `Fichier invalide: La valeur "${fieldInFeature}" n'est pas une option valide dans le champ "${name}" du signalement "${properties.title}".`;
                  this.$store.commit('DISCARD_LOADER');
                  return false;
                }

              // Handle 'pre_recorded_list' by checking if the value matches pre-recorded options
              } else if (field_type === 'pre_recorded_list') {
                if (typeof fieldInFeature === 'string' && fieldInFeature.charAt(0) === '{') { // data from CSV come as string, if it doesn't start with bracket then it should not be converted to an object and stay as a string, since the structure has been simplified: https://redmine.neogeo.fr/issues/18740
                  try {
                    const jsonStr = fieldInFeature.replace(/['‘’"]\s*label\s*['‘’"]\s*:/g, '"label":')
                      .replace(/:\s*['‘’"](.+?)['‘’"]\s*(?=[,}])/g, ':"$1"');
                    fieldInFeature = JSON.parse(jsonStr);
                  } catch (e) {
                    console.error(e);
                    this.DISPLAY_MESSAGE({ comment: `La valeur "${fieldInFeature}" n'a pas pu être vérifiée dans le champ "${name}" du signalement "${properties.title}"` });
                  }
                }
                let fieldLabel = fieldInFeature.label || fieldInFeature;
                const isPreRecordedValue = await this.checkPreRecordedValue(fieldLabel, options[0]);
                if (!isPreRecordedValue) {
                  this.importError = `Fichier invalide: La valeur "${fieldLabel}" ne fait pas partie des valeurs pré-enregistrées dans le champ "${name}" du signalement "${properties.title}".`;
                  this.$store.commit('DISCARD_LOADER');
                  return false;
                }

              // Handle 'multi_choices_list' by checking if each value in the array is among the defined options
              } else if (field_type === 'multi_choices_list') {
                if (typeof fieldInFeature === 'string' && fieldInFeature.charAt(0) === '[') { // data from CSV come as string, if it doesn't start with bracket then there's no need to convert it to an array
                  try {
                    fieldInFeature = JSON.parse(fieldInFeature.replaceAll('\'', '"'));
                  } catch (e) {
                    console.error(e);
                    this.DISPLAY_MESSAGE({ comment: `La valeur "${fieldInFeature}" n'a pas pu être vérifiée dans le champ "${name}" du signalement "${properties.title}"` });
                  }
                }
                // Check that the value is an array before asserting its validity
                if (Array.isArray(fieldInFeature)) {
                  const invalidValues = fieldInFeature.filter((el) => !options.includes(el));
                  if (invalidValues.length > 0) {
                    const plural = invalidValues.length > 1;
                    this.importError = `Fichier invalide: ${plural ? 'Les valeurs' : 'La valeur'} "${invalidValues.join(', ')}" ${plural ? 'ne sont pas des options valides' : 'n\'est pas une option valide'}
                      dans le champ "${name}" du signalement "${properties.title}".`;
                    this.$store.commit('DISCARD_LOADER');
                    return false;
                  }
                } else {
                  this.importError = `Fichier invalide: La valeur "${fieldInFeature}" doit être un tableau dans le champ "${name}" du signalement "${properties.title}".`;
                  this.$store.commit('DISCARD_LOADER');
                  return false;
                }

              // Validate custom field value type
              } else if (customType !== field_type &&
              // at feature type at creation, in case the value was 0, since it can be either float or integer, by default we've set its type as a float
              // when importing features, to avoid an error with different types, we bypass this check when the incoming feature value is a integer while the feature type says it should be a float
                !(
                  // Allow integers where decimals are expected
                  (customType === 'integer' && field_type === 'decimal') ||
                  // Allow numbers formatted as strings when 'char' or 'text' type is expected
                  ((customType === 'integer' || customType === 'float') && field_type === 'char' || field_type === 'text') ||
                  // Allow 'char' values where 'text' (multiline string) is expected
                  (customType === 'char' && field_type === 'text')
                )
              ) {
                this.importError = `Fichier invalide : Le type de champ "${field_type}" ne peut pas avoir la valeur "${fieldInFeature}" dans le champ "${name}" du signalement "${properties.title}".`;
                this.$store.commit('DISCARD_LOADER');
                return false;
              }
            }
          }
        }
        count +=1;
      }
      this.$store.commit('DISCARD_LOADER');
      return true;
    },

    /**
     * Checks the validity of a CSV string. It ensures the CSV uses a recognized delimiter,
     * contains 'lat' and 'lon' headers, and that these columns contain decimal values within valid ranges.
     * Additionally, it verifies the consistency and presence of data in the CSV, and that the types of values are valid.
     *
     * @param {string} csvString - The CSV content in string format.
     * @returns {boolean|Promise<boolean>} Returns a boolean or a Promise resolving to a boolean,
     *                                      indicating the validity of the CSV.
     */
    async checkCsvValidity(csvString) {
      this.importError = '';

      // Determine the delimiter of the CSV
      const delimiter = determineDelimiter(csvString);
      if (!delimiter) {
        this.importError = `Le fichier ${this.csvFileToImport.name} n'est pas formaté correctement`;
        return false;
      }

      // Parse the CSV string into rows
      const rows = parseCSV(csvString, delimiter);

      // Extract headers
      const headers = rows.shift();
      if (this.feature_type.geom_type !== 'none') {
        // Check for required fields 'lat' and 'lon' in headers
        if (!headers.includes('lat') || !headers.includes('lon')) {
          this.importError = 'Les champs obligatoires "lat" et "lon" sont absents des headers.';
          return false;
        }
        // Verify the presence and validity of coordinate values
        const hasCoordValues = checkLonLatValues(headers, rows);
        if (!hasCoordValues) {
          this.importError = 'Les valeurs de "lon" et "lat" ne sont pas valides ou absentes.';
          return false;
        }

      } 

      // Ensure there are data rows after the headers
      if (rows.length === 0) {
        this.importError = 'Aucune donnée trouvée après les en-têtes.';
        return false;
      }

      // Ensure that each row has the same number of columns as the headers
      if (rows.some(row => row.length !== headers.length)) {
        this.importError = 'Incohérence dans le nombre de colonnes par ligne.';
        return false;
      }

      // Convert the CSV string to a JSON object for further processing
      const jsonFromCsv = await csv({ delimiter }).fromString(csvString);

      // Validate the types of values in the JSON object
      const validity = await this.isValidTypes(jsonFromCsv);
      return validity;
    },

    /**
     * Handles the change event for GeoJSON file input. This function is triggered when a user selects a file.
     * It reads the file, checks its validity if it's not too large, and updates the component state accordingly.
     *
     * @param {Event} e - The event triggered by file input change.
     */
    async onGeojsonFileChange(e) {
      // Start loading process
      this.loadingImportFile = true;

      // Clear any previously selected CSV file to avoid confusion
      this.csvFileToImport = csvFileToImport;

      // Retrieve the files from the event
      const files = e.target.files || e.dataTransfer.files;
      
      // If no file is selected, stop the loading process and return
      if (!files.length) {
        this.loadingImportFile = false;
        return;
      }

      const reader = new FileReader();

      /**
       * Asynchronously processes the content of the file.
       * Checks the validity of the GeoJSON file if it's smaller than a certain size.
       * Updates the state with the GeoJSON file if it's valid.
       *
       * @param {string} fileContent - The content of the file read by FileReader.
       */
      const processFile = async (fileContent) => {
        let jsonValidity;

        // Check the file size and determine the GeoJSON validity
        if (parseFloat(fileConvertSizeToMo(files[0].size)) <= 10) {
          // If the file is smaller than 10 Mo, check its validity
          try {
            const json = JSON.parse(fileContent);
            jsonValidity = await this.isValidTypes(json.features || json);
          } catch (error) {
            this.DISPLAY_MESSAGE({ comment: error, level: 'negative' });
            jsonValidity = false;
          }
        } else {
          // Assume validity for larger files
          jsonValidity = true;
        }

        // If the GeoJSON is valid, update the component state with the file and set the file in store
        if (jsonValidity) {
          this.geojsonFileToImport = files[0];
          this.SET_FILE_TO_IMPORT(this.geojsonFileToImport);
        } else {
          // Clear any previously selected geojson file to disable import button
          this.geojsonFileToImport = geojsonFileToImport;
          this.toggleJsonUploadOption(this.feature_type);
        }

        // Stop the loading process
        this.loadingImportFile = false;
      };

      // Setup the load event listener for FileReader
      reader.addEventListener('load', (e) => processFile(e.target.result));

      // Read the text from the selected file
      reader.readAsText(files[0]);
    },

    /**
     * Handles the change event for CSV file input. This function is triggered when a user selects a file.
     * It reads the file, checks its validity if it's not too large, and updates the component state accordingly.
     *
     * @param {Event} e - The event triggered by file input change.
     */
    async onCsvFileChange(e) {
      // Start loading process
      this.loadingImportFile = true;

      // Clear any previously selected geojson file to avoid confusion
      this.geojsonFileToImport = geojsonFileToImport;
      this.toggleJsonUploadOption(this.feature_type);

      // Retrieve the files from the event
      const files = e.target.files || e.dataTransfer.files;
      
      // If no file is selected, stop the loading process and return
      if (!files.length) {
        this.loadingImportFile = false;
        return;
      }

      // Create a new FileReader to read the selected file
      const reader = new FileReader();

      /**
       * Asynchronously processes the content of the file.
       * Checks the validity of the CSV file if it's smaller than a certain size.
       * Updates the state with the CSV file if it's valid.
       *
       * @param {string} fileContent - The content of the file read by FileReader.
       */
      const processFile = async (fileContent) => {
        let csvValidity;

        // Check the file size and determine the CSV validity
        if (parseFloat(fileConvertSizeToMo(files[0].size)) <= 10) {
          // If the file is smaller than 10 Mo, check its validity
          csvValidity = await this.checkCsvValidity(fileContent);
        } else {
          // Assume validity for larger files
          csvValidity = true;
        }

        // If the CSV is valid, update the component state with the file
        if (csvValidity) {
          this.csvFileToImport = files[0]; // TODO: Remove this value from state as it is stored (first attempt didn't work)
          this.SET_FILE_TO_IMPORT(this.csvFileToImport);
        } else {
          // Clear any previously selected geojson file to disable import button
          this.csvFileToImport = csvFileToImport;
        }

        // Stop the loading process
        this.loadingImportFile = false;
      };

      // Setup the load event listener for FileReader
      reader.addEventListener('load', (e) => processFile(e.target.result));

      // Read the text from the selected file
      reader.readAsText(files[0]);
    },

    importGeoJson() {
      this.waitMessage = true;
      const payload = {
        slug: this.slug,
        feature_type_slug: this.featureTypeSlug,
      };
      if (this.$route.params.geojson) { //* import after redirection, for instance with data from catalog
        payload['geojson'] = this.$route.params.geojson;
      } else if (this.geojsonFileToImport.size > 0) { //* import directly from geojson
        payload['fileToImport'] = this.geojsonFileToImport;
      } else {
        this.importError = 'La ressource n\'a pas pu être récupéré.';
        return;
      }
      this.$store.dispatch('feature-type/SEND_FEATURES_FROM_GEOJSON', payload)
        .then(() => {
          this.waitMessage = false;
          this.$refs.importTask.fetchImports();
        });
    },

    importCSV() {
      this.waitMessage = true;
      const payload = {
        slug: this.slug,
        feature_type_slug: this.featureTypeSlug,
      };
      if (this.$route.params.csv) { //* import after redirection, for instance with data from catalog
        payload['csv'] = this.$route.params.csv;
      } else if (this.csvFileToImport.size > 0) { //* import directly from csv file
        payload['fileToImport'] = this.csvFileToImport;
      } else {
        this.importError = "La ressource n'a pas pu être récupéré.";
        return;
      }
      this.SEND_FEATURES_FROM_CSV(payload)
        .then(() => {
          this.waitMessage = false;
          this.$refs.importTask.fetchImports();
        });
    },

    exportFeatures() {
      this.exportLoading = true;
      let exportFormat = this.feature_type.geom_type === 'none' && this.exportFormat === 'GeoJSON' ? 'json' : this.exportFormat.toLowerCase();
      const url = `
        ${this.$store.state.configuration.VUE_APP_DJANGO_API_BASE}projects/${this.slug}/feature-type/${this.featureTypeSlug}/export/?format_export=${exportFormat}
      `;
      featureAPI.getFeaturesBlob(url)
        .then((blob) => {
          if (blob) {
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = `${this.project.title}-${this.feature_type.title}.${exportFormat}`;
            link.click();
            setTimeout(function(){
              URL.revokeObjectURL(link.href); 
            }, 1000);
          }
          this.exportLoading = false;
        })
        .catch(() => {
          this.exportLoading = false;
        });
    },
  },
};
</script>

<style scoped lang="less">

#feature-type-title i {
  color: #000000;
  margin: auto;
}
.custom-field.content {
  overflow: hidden;
  text-overflow: ellipsis;
}
.margin-25 {
  margin: 0 0.25em 0.25em 0 !important;
}

.import-catalog {
  margin-bottom: 1em;
}

.nohover, .nohover:hover {
  cursor: default;
}

.ui.styled.accordion .nohover.title:hover {
  color: rgba(0, 0, 0, .4);
}

.ui.styled.accordion {
  .content {
    .field {
      label {
        width: 100%;
      }
    }
    .import-catalog {
      width: 100%;
    }
  }
}
</style>